/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  margin: 12px 0 0;
}
div.sub1 .menu {
  font-size: 18px;
  font-size: 1.8rem;
}
/* LEVEL 2 */
div.sub2 {
  clear: both;
  float: right;
  margin-top: 55px;
  padding-left: 1.8em;
  border-bottom: 1px solid #4f72aa;
}
div.sub2 > .item {
  margin-bottom: -0.6em;
}
div.sub2 .menu {
  font-size: 18px;
  font-size: 1.8rem;
  padding: 0.6em 0 1.1em;
  line-height: 1.1;
  margin-left: 0.845em;
  margin-right: 0.845em;
  padding-left: 3px;
}
div.sub2 > .exit > .menu {
  padding-right: 0;
}
div.sub2 a.path {
  font-weight: normal;
  font-family: "OpenSansBold", sans-serif;
  position: relative;
  background: url(/images/navi.png) repeat-x 0 100%;
}
div.sub2 a.path:before {
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 16px;
  width: 4px;
  background: url(/images/navi-left.png) no-repeat 0 0;
  content: " ";
}
div.sub2 a.path:after {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 16px;
  width: 4px;
  background: url(/images/navi-right.png) no-repeat 0 0;
  content: " ";
}
div.sub2 > .exit > .path {
  background-position: 90% 100%;
}
/* LEVEL 3 */
div.sub3 {
  margin-top: 24px;
}
div.sub3,
div.sub3 > .item {
  width: 100%;
}
div.sub3 .menu {
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: -1em;
  padding: 0.25em;
  padding-left: 1em;
  color: #6f7072;
  line-height: 1.1;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus,
div.sub3 .menu.path {
  color: #0072c3;
}
div.sub3 a.path {
  background: url(/images/sub3-path.svg) no-repeat 0;
}
div.sub3 a.path > div {
  background: none !important;
}
#find {
  float: right;
  clear: right;
  margin: 23px 0 0;
}
#find #searchfield {
  width: 153px;
}
/* SERVICES */
/******* layout-large.less 2013-1-16 *******/
#home {
  width: 63.73801917%;
}
#head {
  width: 64.08730159%;
  margin-right: 0;
  margin-top: 5.7%;
}
.cb-layout1 #head {
  width: 97.42063492%;
}
#desk {
  min-height: 100%;
  width: 1008px;
}
.logo-claim {
  margin: 26px 0 0 -11.11111111%;
  width: 62.1031746%;
}
.claim {
  bottom: 0;
  right: 0;
  position: absolute;
  padding-bottom: 2.87539936%;
  width: 33.54632588%;
}
.mood {
  margin-top: 2.7%;
  margin-right: 0;
  min-height: 309px;
  width: 91.86507937%;
}
.mood-overlay {
  left: -24.5%;
}
.navi-search {
  position: absolute;
  top: 0;
  right: 0;
}
.subnavigation {
  float: left;
  margin: 5.5% -2.18253968% 0 2.18253968%;
  padding-bottom: 7em;
  width: 33.33333333%;
}
.sub2copy {
  font-size: 23px;
  font-size: 2.3rem;
  display: block;
  float: left;
  border-bottom: 1px solid #4f72aa;
  color: #0072c3;
  white-space: nowrap;
  line-height: 1.3;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
}
.vcard,
.post-office-box {
  float: left;
  padding-bottom: 2.2em;
  background: url(/images/vcard.svg) no-repeat 100% 100%;
}
.vcard {
  margin-left: 2.57936508%;
  padding-right: 1.6em;
}
.post-office-box {
  margin-bottom: -2.2em;
  margin-left: -1.5em;
  padding-left: 1.3em;
  background-position: 0 100%;
}
#cmsbox {
  float: right;
  padding: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.93452381%;
  margin-left: 1.93452381%;
}
.area .part,
.area > .grid table {
  margin-right: 1.93452381%;
  margin-left: 1.93452381%;
  width: 96.13095238%;
}
.area .tiny {
  width: 46.13095238%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.86904762%;
  margin-left: 3.86904762%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.26190476%;
}
.area > .slim .tiny {
  width: 42.26190476%;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 1.28968254%;
  margin-left: 1.28968254%;
  width: 97.42063492%;
}
.cb-layout1 .area h2,
.cb-layout1 .area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout1 .area .part,
.cb-layout1 .area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area > .slim {
  width: 30.75396825%;
}
.cb-layout1 .area > .slim h2,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  width: 100%;
}
.cb-layout1 .area > .slim .tiny {
  width: 100%;
}
.cb-layout4 .area {
  width: 100%;
}
.cb-layout4 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout4 .area h2,
.cb-layout4 .area .foot {
  margin-right: 1.93452381%;
  margin-left: 1.93452381%;
}
.cb-layout4 .area .part,
.cb-layout4 .area > .grid table {
  margin-right: 1.93452381%;
  margin-left: 1.93452381%;
  width: 96.13095238%;
}
.cb-layout4 .area .tiny {
  width: 46.13095238%;
}
.cb-layout4 .area > .slim {
  width: 25%;
}
.cb-layout4 .area > .slim h2,
.cb-layout4 .area > .slim .foot,
.cb-layout4 .area > .slim .part,
.cb-layout4 .area > .slim.grid table {
  margin-right: 7.73809524%;
  margin-left: 7.73809524%;
}
.cb-layout4 .area > .slim .part,
.cb-layout4 .area > .slim.grid table {
  width: 84.52380952%;
}
.cb-layout4 .area > .slim .tiny {
  width: 84.52380952%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.area {
  margin-right: -1.28968254%;
  padding-bottom: 7em;
}
.cb-layout2 .area,
.cb-layout4 .area {
  width: 66.66666667%;
}
h1 {
  font-size: 23px;
  font-size: 2.3rem;
}
.cb-layout1 .wide {
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.41176471;
  padding-bottom: 2.4em;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */